import React from "react";
import { Link } from "gatsby";
import { PageLinks } from "../../common/site/page-static-links";

const PropertyDetailsMapContent = (props) => {
    // Split the title by commas
    const titleParts = props.title.split(',');

    // Check if there are multiple parts; if so, use the second part (trimmed), otherwise use the whole title
    const displayTitle = titleParts.length > 1 ? titleParts[1].trim() : props.title;

    return (
        <div className="desc-link-text"><span>Want to explore {displayTitle} further?</span> Explore <Link to={`/${PageLinks.areaguide}/`} className="link-underline">our community guide</Link>.</div>
    )
}

export default PropertyDetailsMapContent